import axios from 'axios';

const PATH = '/controllers';

const subcollection_outputs = 'outputs';
const subcollection_inputs = 'inputs';

export const ControllerService = {
  list: async params => {
    return (await axios.get(PATH, { params })).data;
  },
  get: async id => (await axios.get(`${PATH}/${id}`)).data,
  create: async controller => await axios.post(PATH, controller),
  update: async controller => await axios.put(PATH, controller),
  delete: async id => await axios.delete(`${PATH}/${id}`),
  OUTPUTS: {
    update: async output =>
      await axios.put(
        `${PATH}/${output.controllerId}/${subcollection_outputs}`,
        output,
      ),
    getAll: async controllerId =>
      (await axios.get(`${PATH}/${controllerId}/${subcollection_outputs}`))
        .data,
  },
  INPUTS: {
    update: async input =>
      await axios.put(
        `${PATH}/${input.controllerId}/${subcollection_inputs}`,
        input,
      ),
    getAll: async controllerId =>
      (await axios.get(`${PATH}/${controllerId}/${subcollection_inputs}`)).data,
  },
};
