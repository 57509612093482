import { Suspense } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Chart, registerables } from 'chart.js';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import { GlobalStyles } from '@mui/system';

import 'chartjs-adapter-date-fns';

import './assets/scss/DefaultTheme.scss';
import './assets/css/tree_maker.css';
import 'react-datepicker/dist/react-datepicker.css';

import { ThemeWrapper } from '@components/common/ThemeWrapper';

import { PreLoaderWidget } from './components/common/PreLoaderWidget';
import { UserProvider } from './providers/UserProvider';
import { routes } from './routes';
import { initAPIService } from './services/api.service';

Chart.register(...registerables);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  initAPIService();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ThemeWrapper>
          <GlobalStyles
            styles={theme => ({
              html: {
                backgroundColor: theme.palette.rootBackground.default,
              },
              body: {
                backgroundColor: theme.palette.rootBackground.default,
              },
            })}
          ></GlobalStyles>
          <UserProvider>
            <Toaster />
            {routes.map((route, index) => {
              return (
                <route.route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  allowedPermission={route.allowedPermission}
                  adminRequired={route.adminRequired}
                  roles={route.roles}
                  component={props => {
                    return (
                      <Suspense fallback={<PreLoaderWidget />}>
                        <route.component {...props} />
                      </Suspense>
                    );
                  }}
                />
              );
            })}
          </UserProvider>
        </ThemeWrapper>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
