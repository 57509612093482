import { lazy } from 'react';

import { PrivateRoute } from '../components/common/PrivateRoute';

const FacilitiesConfigurationPage = lazy(() =>
  import('@pages/configurations/FacilitiesConfigurationPage'),
);
const FacilityConfigurationPage = lazy(() =>
  import('@pages/configurations/FacilityConfigurationPage'),
);

const ConfigureIrrigationZonesPage = lazy(() =>
  import('@pages/configurations/ConfigureIrrigationZonesPage'),
);

const ConfigureLightZonesPage = lazy(() =>
  import('@pages/configurations/ConfigureLightZonesPage'),
);

const TimeControlZonesConfigurationPage = lazy(() =>
  import('@pages/configurations/TimeControlZonesConfigurationPage'),
);
const TimeControlZoneConfiguration = lazy(() =>
  import('@pages/configurations/TimeControlZoneConfigurationPage'),
);

const ConfigureEnvironmentsPage = lazy(() =>
  import('@pages/configurations/ConfigureEnvironmentsPage'),
);
const ConfigureEnvironmentPage = lazy(() =>
  import('@pages/configurations/ConfigureEnvironmentPage'),
);

const ConfigureEnvironmentalZonesPage = lazy(() =>
  import('@pages/configurations/ConfigureEnvironmentalZonesPage'),
);

const EnvironmentalControlZonesConfigurationPage = lazy(() =>
  import('@pages/configurations/EnvironmentalControlZonesConfigurationPage'),
);
const EnvironmentalControlZoneConfigurationPage = lazy(() =>
  import('@pages/configurations/EnvironmentalControlZoneConfigurationPage'),
);

const RoomsConfiguration = lazy(() =>
  import('@pages/configurations/ConfigureRooms'),
);
const RoomsFacilityConfiguration = lazy(() =>
  import('@pages/configurations/ConfigureRoom'),
);

const ModbusConfigurationPage = lazy(() =>
  import('@pages/configurations/ModbusConfigurationPage'),
);
const SensorConfigurationPage = lazy(() =>
  import('@pages/configurations/SensorConfigurationPage'),
);

const ConfigureWaterSensorInterfacesPage = lazy(() =>
  import('@pages/configurations/ConfigureWaterSensorInterfacesPage'),
);
const ConfigureWaterSensorInterfacePage = lazy(() =>
  import('@pages/configurations/ConfigureWaterSensorInterfacePage'),
);
const ConfigureWaterSensorsPage = lazy(() =>
  import('@pages/configurations/ConfigureWaterSensorsPage'),
);
const ConfigureWaterSensorPage = lazy(() =>
  import('@pages/configurations/ConfigureWaterSensorPage'),
);

const ConfigureWaterQualityZonesPage = lazy(() =>
  import('@pages/configurations/ConfigureWaterQualityZonesPage'),
);

const MeasurablesConfiguration = lazy(() =>
  import('@pages/configurations/MeasurablesPage'),
);

const StrainsConfiguration = lazy(() =>
  import('@pages/configurations/ConfigureStrainsPage'),
);
const ConfigureTrayTypesPage = lazy(() =>
  import('@pages/configurations/ConfigureTrayTypesPage'),
);
const ConfigureProductTypesPage = lazy(() =>
  import('@pages/configurations/ConfigureProductTypesPage'),
);
const ConfigureProductTreesPage = lazy(() =>
  import('@pages/configurations/ConfigureProductTreesPage'),
);
const ConfigureProductModelsPage = lazy(() =>
  import('@pages/configurations/ConfigureProductModelsPage'),
);
const ConfigureProductAllocationsPage = lazy(() =>
  import('@pages/configurations/ConfigureProductAllocationsPage'),
);
const ConfigureProductLocationsPage = lazy(() =>
  import('@pages/configurations/ConfigureProductLocationsPage'),
);
const ConfigureProductProcessorsPage = lazy(() =>
  import('@pages/configurations/ConfigureProductProcessorsPage'),
);
const ConfigureProductYieldsPage = lazy(() =>
  import('@pages/configurations/ConfigureProductYieldsPage'),
);

export const configurationsRoutes = [
  {
    path: '/configuration/facility',
    exact: true,
    name: 'FacilitiesConfiguration',
    component: FacilitiesConfigurationPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_FACILITIES_TAB',
  },
  {
    path: '/configuration/facility/:id',
    exact: true,
    name: 'FacilityConfiguration',
    component: FacilityConfigurationPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_FACILITIES_TAB',
  },

  {
    path: '/configuration/irrigationZones',
    name: 'IrrigationZones',
    component: ConfigureIrrigationZonesPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_IRRIGATION_ZONES_TAB',
  },

  {
    path: '/configuration/lightZones',
    name: 'LightZones',
    component: ConfigureLightZonesPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_LIGHT_ZONES_TAB',
  },

  {
    path: '/configuration/timeControlZones',
    exact: true,
    name: 'Time Control Zones Configuration',
    component: TimeControlZonesConfigurationPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_TIME_CONTROL_ZONES_TAB',
  },
  {
    path: '/configuration/timeControlZone/:id',
    exact: true,
    name: 'Time Control Zone Configuration',
    component: TimeControlZoneConfiguration,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_TIME_CONTROL_ZONES_TAB',
  },

  {
    path: '/configuration/environments',
    name: 'Environmental Sensors Configuration',
    component: ConfigureEnvironmentsPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_ENVIRONMENTAL_SENSORS_TAB',
  },
  {
    path: '/configuration/environment/:id',
    name: 'Environmental Sensor Configuration',
    component: ConfigureEnvironmentPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_ENVIRONMENTAL_SENSORS_TAB',
  },

  {
    path: '/configuration/environmentalZones',
    name: 'Environmental Zones Configuration',
    component: ConfigureEnvironmentalZonesPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_ENVIRONMENTAL_ZONES_TAB',
  },

  {
    path: '/configuration/environmentalControlZones',
    exact: true,
    name: 'Environmental Control Zones Configuration',
    component: EnvironmentalControlZonesConfigurationPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_ENVIRONMENTAL_CONTROL_ZONES_TAB',
  },
  {
    path: '/configuration/environmentalControlZone/:id',
    exact: true,
    name: 'Environmental Control Zone Configuration',
    component: EnvironmentalControlZoneConfigurationPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_ENVIRONMENTAL_CONTROL_ZONES_TAB',
  },
  {
    path: '/configuration/waterSensorInterfaces',
    name: 'Water Sensor Interfaces Configuration',
    component: ConfigureWaterSensorInterfacesPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_WATER_SENSOR_INTERFACES_TAB',
  },
  {
    path: '/configuration/waterSensorInterface/:id',
    name: 'Water Sensor Interface Configuration',
    component: ConfigureWaterSensorInterfacePage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_WATER_SENSOR_INTERFACES_TAB',
  },
  {
    path: '/configuration/waterSensors',
    name: 'Water Sensors Configuration',
    component: ConfigureWaterSensorsPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_WATER_SENSORS_TAB',
  },
  {
    path: '/configuration/waterSensor/:id',
    name: 'Water Sensor Configuration',
    component: ConfigureWaterSensorPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_WATER_SENSORS_TAB',
  },

  {
    path: '/configuration/waterQualityZones',
    name: 'Water Quality Zones Configuration',
    component: ConfigureWaterQualityZonesPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_WATER_QUALITY_ZONES_TAB',
  },

  {
    path: '/configuration/rooms',
    exact: true,
    name: 'RoomsConfiguration',
    component: RoomsConfiguration,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_ROOMS_TAB',
  },
  {
    path: '/configuration/rooms/:id',
    exact: true,
    name: 'RoomsFacilityConfiguration',
    component: RoomsFacilityConfiguration,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_ROOMS_TAB',
  },

  {
    path: '/configuration/modbus',
    exact: true,
    name: 'ModbusConfiguration',
    component: ModbusConfigurationPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_MODBUS_TAB',
  },
  {
    path: '/configuration/modbus/:id',
    exact: true,
    name: 'Sensor Configuration',
    component: SensorConfigurationPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_MODBUS_TAB',
  },
  {
    path: '/configuration/measurables',
    exact: true,
    name: 'Measurable Type Configuration',
    component: MeasurablesConfiguration,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_MEASURABLES_TAB',
  },
  {
    path: '/configuration/strains',
    exact: true,
    name: 'Strains Configuration',
    component: StrainsConfiguration,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_STRAINS_TAB',
  },
  {
    path: '/configuration/tray-types',
    exact: true,
    name: 'Tray Types Configuration',
    component: ConfigureTrayTypesPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_TRAY_TYPES_TAB',
  },
  {
    path: '/configuration/product-types',
    exact: true,
    name: 'Product Types Configuration',
    component: ConfigureProductTypesPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_PRODUCT_TYPES_TAB',
  },
  {
    path: '/configuration/product-trees',
    exact: true,
    name: 'Product Trees Configuration',
    component: ConfigureProductTreesPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_PRODUCT_TREES_TAB',
  },
  {
    path: '/configuration/product-models',
    exact: true,
    name: 'Product Models Configuration',
    component: ConfigureProductModelsPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_PRODUCT_MODELS_TAB',
  },
  {
    path: '/configuration/product-allocations',
    exact: true,
    name: 'Product Allocations Configuration',
    component: ConfigureProductAllocationsPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_PRODUCT_ALLOCATIONS_TAB',
  },
  {
    path: '/configuration/product-locations',
    exact: true,
    name: 'Product Locations Configuration',
    component: ConfigureProductLocationsPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_PRODUCT_LOCATIONS_TAB',
  },
  {
    path: '/configuration/product-processors',
    exact: true,
    name: 'Product Processors Configuration',
    component: ConfigureProductProcessorsPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_PRODUCT_PROCESSORS_TAB',
  },
  {
    path: '/configuration/product-yields',
    exact: true,
    name: 'Product Yields Configuration',
    component: ConfigureProductYieldsPage,
    route: PrivateRoute,
    allowedPermission: 'CONFIGURATION_PRODUCT_YIELDS_TAB',
  },
];
