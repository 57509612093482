import { useState, useEffect, memo } from 'react';

import { Alert } from '@mui/material';

import { findInactiveControllers } from '@utils/findInactiveControllers';

import { ControllerService } from '@features/controllers/services';

export const AlertBar = memo(({ facility }) => {
  const [inactiveControllers, setInactiveControllers] = useState();

  useEffect(() => {
    if (!facility) {
      return;
    }

    let mounted = true;
    const loadControllers = async () => {
      const controllersResult = await ControllerService.list({
        facility_id: facility.id,
      });

      const inactive = findInactiveControllers(controllersResult);

      if (mounted) {
        setInactiveControllers(inactive);
      }
    };

    loadControllers();

    return () => {
      mounted = false;
    };
  }, [facility]);

  return (
    <header id="alertBar">
      {inactiveControllers &&
        inactiveControllers.map(c => (
          <Alert
            key={c.id}
            severity="error"
            style={{
              padding: '0 0.5rem',
              margin: '0.5rem 0',
            }}
          >
            {c.name} disconnected
          </Alert>
        ))}
    </header>
  );
});

AlertBar.displayName = 'AlertBar';
