import { useContext } from 'react';

import { checkPermissionAccess } from '@utils/checkPermissionAccess';
import { auth } from '@utils/firebase';
import { Redirect, Route, useLocation, useHistory } from 'react-router-dom';

import { useRolesStore } from '@features/roles/hooks';

import { UserContext } from '@providers/UserProvider';

import { AuthLayout } from './AuthLayout';

const LoginWithLink = ({ location, showSnackbar }) => {
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  const login = async () => {
    try {
      await auth.signInWithEmailLink(email, window.location.href);
      await auth.currentUser.updateProfile({
        emailVerified: true,
      });
    } catch (e) {
      if (e?.code === 'auth/invalid-action-code') {
        history.push(`/refresh-invite?email=${email}`);
      } else {
        showSnackbar('error', e?.message);
        history.push('/');
      }
    }
  };

  login();
  return <></>;
};

export const PrivateRoute = ({
  component: Component,
  allowedPermission = null,
  adminRequired = false,
  ...rest
}) => {
  const { currentUser, showSnackbar, facility } = useContext(UserContext);
  const location = useLocation();
  const { rolesData } = useRolesStore();

  return (
    <Route
      {...rest}
      render={props => {
        if (location.search.includes('apiKey') && !currentUser) {
          return (
            <LoginWithLink location={location} showSnackbar={showSnackbar} />
          );
        }
        // check if current user is not logged in
        if (!currentUser) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }
        // check if email is not verified
        if (!currentUser.emailVerified) {
          return <Redirect to="/confirm" />;
        }

        if (
          !checkPermissionAccess(
            allowedPermission,
            currentUser,
            facility,
            rolesData,
            adminRequired,
          )
        ) {
          return (
            <AuthLayout>
              <Redirect to="/overview/facility" />
            </AuthLayout>
          );
        }
        return (
          <AuthLayout>
            <Component {...props} />
          </AuthLayout>
        );
      }}
    />
  );
};
