import { lazy } from 'react';

import { Route } from 'react-router-dom';

import { PrivateRoute } from '../components/common/PrivateRoute';

const Login = lazy(() => import('@pages/auth/Login'));
const Logout = lazy(() => import('@pages/auth/Logout'));
const ForgetPassword = lazy(() => import('@pages/auth/ForgetPassword'));
const Register = lazy(() => import('@pages/auth/Register'));
const ConfirmAccount = lazy(() => import('@pages/auth/Confirm'));
const RefreshInvite = lazy(() => import('@pages/auth/RefreshInvite'));
const UserManagement = lazy(() => import('@pages/account/UserManagement'));
const Permissions = lazy(() => import('@pages/account/Permissions'));
const Roles = lazy(() => import('@pages/account/Roles'));

export const authRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    route: Route,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    route: Route,
  },
  {
    path: '/forget-password',
    name: 'Forget Password',
    component: ForgetPassword,
    route: Route,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    route: Route,
  },
  {
    path: '/confirm',
    name: 'Confirm',
    component: ConfirmAccount,
    route: Route,
  },
  {
    path: '/user-management',
    name: 'User management',
    component: UserManagement,
    route: PrivateRoute,
    adminRequired: true,
  },
  {
    path: '/permissions',
    name: 'Permissions',
    component: Permissions,
    route: PrivateRoute,
    adminRequired: true,
  },
  {
    path: '/roles',
    name: 'Roles',
    component: Roles,
    route: PrivateRoute,
    adminRequired: true,
  },
  {
    path: '/refresh-invite',
    name: 'Refresh invite ',
    component: RefreshInvite,
    route: Route,
  },
];
