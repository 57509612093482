import BlockIcon from '@mui/icons-material/Block';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/PersonOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

export const profileNav = currentUser => {
  return {
    name: 'Account',
    icon: <PersonOutlineIcon style={{ marginRight: '5px' }} />,
    children: [
      {
        name: `Not ${currentUser.displayName || currentUser.email}? Logout`,
        link: '/logout',
        icon: <LogoutIcon />,
      },
      {
        name: `User Management`,
        link: '/user-management',
        adminOnly: true,
        icon: <PersonIcon />,
      },
      {
        name: `Permissions`,
        link: '/permissions',
        adminOnly: true,
        icon: <EmojiFlagsIcon />,
      },
      {
        name: `Roles`,
        link: '/roles',
        adminOnly: true,
        icon: <BlockIcon />,
      },
    ],
  };
};
