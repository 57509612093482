import { lazy } from 'react';

import { PrivateRoute } from '../components/common/PrivateRoute';

const PlantInventory = lazy(() => import('../pages/inventory/PlantInventory'));
const ProductInventory = lazy(() =>
  import('../pages/inventory/ProductInventory'),
);
const AgingInventory = lazy(() => import('../pages/inventory/AgingInventory'));
const CurrentProductInventory = lazy(() =>
  import('../pages/inventory/CurrentProductInventory'),
);
const ProjectedProductInventoryPage = lazy(() =>
  import('../pages/inventory/ProjectedProductInventoryPage'),
);

const ContainersPage = lazy(() => import('../pages/inventory/Containers'));

export const inventoryRoutes = [
  {
    path: '/inventory/plant-inventory',
    exact: true,
    component: PlantInventory,
    route: PrivateRoute,
    allowedPermission: 'INVENTORY_PLANT_INVENTORY_TAB',
  },
  {
    path: '/inventory/product-inventory',
    exact: true,
    component: ProductInventory,
    route: PrivateRoute,
    allowedPermission: 'INVENTORY_PRODUCT_INVENTORY_TAB',
  },
  {
    path: '/inventory/aging-inventory',
    exact: true,
    component: AgingInventory,
    route: PrivateRoute,
    allowedPermission: 'INVENTORY_AGING_INVENTORY_TAB',
  },
  {
    path: '/inventory/current-product-inventory',
    exact: true,
    component: CurrentProductInventory,
    route: PrivateRoute,
    allowedPermission: 'INVENTORY_CURRENT_PRODUCT_INVENTORY_TAB',
  },
  {
    path: '/inventory/projected-product-inventory',
    exact: true,
    component: ProjectedProductInventoryPage,
    route: PrivateRoute,
    allowedPermission: 'INVENTORY_PROJECTED_PRODUCT_INVENTORY_TAB',
  },
  {
    path: '/inventory/containers',
    exact: true,
    component: ContainersPage,
    route: PrivateRoute,
    allowedPermission: 'INVENTORY_CONTAINERS_TAB',
  },
];
