import { lazy } from 'react';

import { PrivateRoute } from '../components/common/PrivateRoute';

const FacilityOverview = lazy(() =>
  import('../pages/overviews/FacilityOverview'),
);
const RoomOverview = lazy(() => import('../pages/overviews/RoomOverview'));
const CultivationGroupsOverview = lazy(() =>
  import('../pages/overviews/CultivationGroupsOverview'),
);

export const overviewsRoutes = [
  {
    path: '/overview/facility/:id?',
    name: 'Facility Overview',
    component: FacilityOverview,
    route: PrivateRoute,
    allowedPermission: 'OVERVIEW_FACILITY_TAB',
  },
  {
    path: '/overview/room/:id?',
    name: 'Room Overview',
    component: RoomOverview,
    route: PrivateRoute,
    allowedPermission: 'OVERVIEW_FACILITY_TAB',
  },

  {
    path: '/overview/cultivationGroups',
    exact: true,
    name: 'Cultivation Groups Overview',
    component: CultivationGroupsOverview,
    route: PrivateRoute,
    allowedPermission: 'OVERVIEW_CULTIVATION_GROUPS_TAB',
  },
];
