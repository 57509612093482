import { lazy } from 'react';

import { PrivateRoute } from '../components/common/PrivateRoute';

const CultivationGroupPlanning = lazy(() =>
  import('../pages/planning/cultivationGroup'),
);

export const planningRoutes = [
  {
    path: '/planning/cultivationGroup',
    exact: true,
    component: CultivationGroupPlanning,
    route: PrivateRoute,
    allowedPermission: 'PLANNING_CULTIVATION_GROUP_TAB',
  },
];
