import axios from 'axios';

import { API_URL } from '@services/api.service';

const PATH = `${API_URL}/user_permissions`;

export const UserPermissionsService = {
  get: (id, params) => axios.get(`${PATH}/${id}`, { params }),
  list: async () => (await axios.get(PATH)).data,
  patch: async params => await axios.patch(PATH, { params }),
};
